var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      !_vm.loading
        ? _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c(
                "v-card",
                { attrs: { elevation: "10" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "z-index": "9" },
                      attrs: { dense: "", color: "#003d6a", dark: "" },
                    },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("reservations"))),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "date-range-picker",
                        {
                          ref: "picker",
                          style: !_vm.isMobile() ? "min-width: 350px" : "",
                          attrs: {
                            opens: "left",
                            singleDatePicker: "range",
                            alwaysShowCalendars: false,
                            ranges: _vm.ranges,
                          },
                          model: {
                            value: _vm.searchBody,
                            callback: function ($$v) {
                              _vm.searchBody = $$v
                            },
                            expression: "searchBody",
                          },
                        },
                        [
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("date")(_vm.searchBody.startDate)
                                ) +
                                " - " +
                                _vm._s(_vm._f("date")(_vm.searchBody.endDate)) +
                                " "
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6", lg: "12", md: "12" } },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.reservationData, function (item, index) {
                              return _c(
                                "v-col",
                                {
                                  key: index,
                                  attrs: {
                                    cols: "6",
                                    sm: "6",
                                    lg: "2",
                                    md: "4",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/reservation/index/?" +
                                          "startDate=" +
                                          _vm.searchBody.startDate +
                                          "&&" +
                                          "endDate=" +
                                          _vm.searchBody.endDate +
                                          "&&" +
                                          "status=" +
                                          item.statusies,
                                      },
                                    },
                                    [
                                      _c(
                                        "CCallout",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { color: item.status.color },
                                        },
                                        [
                                          _c(
                                            "small",
                                            { staticClass: "text-muted" },
                                            [_vm._v(_vm._s(item.status.name))]
                                          ),
                                          _c("br"),
                                          _c("strong", { staticClass: "h4" }, [
                                            _vm._v(_vm._s(item.length)),
                                          ]),
                                          _c("br"),
                                          _c("strong", { staticClass: "h6" }, [
                                            _vm._v(_vm._s(item.price)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "12", lg: "12" } },
                        [
                          _c("BarChart", {
                            attrs: {
                              queries: _vm.searchBody,
                              labels: _vm.vendorLabels,
                              datasets: _vm.datasets,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isPartner
                ? _c(
                    "v-card",
                    { staticClass: "mt-7", attrs: { elevation: "10" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "#003d6a", dark: "", dense: "" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(" " + _vm._s(_vm.$t("last_search"))),
                          ]),
                        ],
                        1
                      ),
                      _c("v-simple-table", {
                        attrs: { "fixed-header": "", height: "500px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Alış"),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("İade"),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Araç"),
                                      ]),
                                      _c("th", { staticClass: "text-right" }, [
                                        _vm._v("Arama"),
                                      ]),
                                      _c("th", { staticClass: "text-right" }),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.nowSearchRowData,
                                      function (item) {
                                        return _c("tr", { key: item._id }, [
                                          _c("td", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  item.pickup_location_name
                                                )
                                              ),
                                            ]),
                                            _c("br"),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .moment(
                                                      item.pickup_datetime
                                                    )
                                                    .format("DD.MM.YYYY HH:mm")
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c("td", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  item.return_location_name
                                                )
                                              ),
                                            ]),
                                            _c("br"),
                                            _c("small", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .moment(
                                                      item.return_datetime
                                                    )
                                                    .format("DD.MM.YYYY HH:mm")
                                                )
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                _vm._s(item.resultCarCount)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm
                                                      .moment(item.createdAt)
                                                      .local()
                                                      .format(
                                                        "DD.MM.YYYY HH:mm"
                                                      )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      _vm.APP_URL +
                                                      "arac-kiralama/" +
                                                      item.pickup_location +
                                                      "/ara?sID=" +
                                                      item._id,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("mdi-arrow-right")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2569225297
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isPartner
                ? _c(
                    "v-card",
                    { staticClass: "mt-7", attrs: { elevation: "10" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "#003d6a", dense: "", dark: "" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(_vm._s(_vm.$t("notifications")) + " "),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", lg: "5" } },
                        _vm._l(_vm.infos, function (info, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickInfo(info)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "progress-group-header" },
                                [
                                  _c("CIcon", {
                                    staticClass: "progress-group-icon",
                                    attrs: { name: info.icon },
                                  }),
                                  _vm._v(" " + _vm._s(info.name) + " "),
                                  _c(
                                    "span",
                                    { staticClass: "ml-auto font-weight-bold" },
                                    [_vm._v(_vm._s(info.count))]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "progress-group-bars" },
                                [
                                  _c("CProgress", {
                                    staticClass: "progress-xs",
                                    attrs: {
                                      value: info.valule,
                                      color: "warning",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", { staticClass: "w-100" }, [_c("gogo-loading")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }