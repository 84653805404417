var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "barWr" },
    [
      _c("Bar", {
        staticStyle: { "min-height": "350px" },
        attrs: {
          "chart-options": _vm.chartOptions,
          "chart-data": _vm.chartData,
          "chart-id": _vm.chartId,
          "dataset-id-key": _vm.datasetIdKey,
          plugins: _vm.plugins,
          "css-classes": _vm.cssClasses,
          height: 100,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }