<template>
  <CCard>
    <CCardHeader>
      <CRow
        style="display: flex; justify-content: space-between; padding: 0 10px"
      >
        <h4>Lokasyon Arama Sonuçları</h4>

        <div class="d-flex justify-content-end">
          <input
            type="text"
            class="form-control"
            style="max-width: 250px"
            autocomplete="new"
            placeholder="Arama..."
            v-on:keyup="onChangFilterLocation"
          />
        </div>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <ag-grid-vue
        style="height: 200px; width: 100%"
        class="ag-theme-balham fp-table"
        :columnDefs="columnDefs"
        :rowData="rowData"
        :defaultColDef="defaultColDef"
      >
      </ag-grid-vue>
    </CCardBody>
  </CCard>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { locationInfosDefs } from "../../columnDefs";
import axios from "axios";
export default {
  name: "LocaitonInfos",
  data() {
    return {
      columnDefs: null,
      rowData: null,
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
      },
    };
  },
  components: {
    AgGridVue,
  },

  async beforeMount() {
    this.columnDefs = locationInfosDefs;
  },
  created() {
    this.getLocationInfos();
  },
  methods: {
    onChangFilterLocation(event) {
      const value = String(event.target.value).toLocaleUpperCase();
      if (value.length > 1) {
        this.rowData = this.rowData.filter((item) => {
          const moun = item.locationName.toLocaleUpperCase();
          if (moun.includes(value)) return item;
        });
      } else {
        this.getLocationInfos();
      }
    },
    async getLocationInfos() {
      try {
        const { data } = await axios.get(
          process.env.VUE_APP_API_URL + "admin/dashboard/locationInfos",
          {
            withCredentials: true,
          }
        );
        this.rowData = data;
      } catch (error) {
        console.log(error.response);
      }
    },
  },
};
</script>
