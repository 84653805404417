<template>
  <div class="barWr">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :height="100"
      style="min-height: 350px"
    />
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
    },
    datasets: {
      type: Array,
    },
    queries: {
      type: Object,
    },
  },
  data() {
    const createRedirectUrl = (labelIndex, datasetIndex) => {
      const brandSlug = this.labels[labelIndex].brandSlug,
        statusies = this.datasets[datasetIndex].statusies;
      const { endDate, startDate } = this.queries;

      return (
        process.env.VUE_APP_ADMIN_URL +
        "reservation/index/?" +
        "vendor=" +
        brandSlug +
        "&&" +
        "status=" +
        statusies +
        "&&" +
        "startDate=" +
        startDate +
        "&&" +
        "endDate=" +
        endDate
      );
    };
    return {
      chartData: {
        labels: this.labels.map((item) => item.brand),
        datasets: this.datasets,
      },
      chartOptions: {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: "x",
        elements: {
          bar: {
            borderWidth: 3,
          },
        },
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: this.$t("dashboard.supplier_based_reservations"),
          },
        },
      },
    };
  },
};
</script>
<style>
.barWr {
  width: 100%;
}
</style>
